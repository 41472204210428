import { defineStore } from 'pinia';
import type IMenuItem from '@/types/dto/menuItem';
import { changeSiteUrls } from '@/store/pinia/menu/helpers';

interface IMenuStore {
  isMobileMenuOpen: boolean;
  enabledLoginBtn: boolean;
  menu: IMenuItem[];
  activeMenu?: IMenuItem | null;
  isUserMenuOpen: boolean;
  stateMenuParcel: string;
  showPanelToMenu: boolean;
}

export const useMenuStore = defineStore('menu', {
  state: (): IMenuStore => {
    return {
      isMobileMenuOpen: false,
      menu: [],
      activeMenu: null,
      enabledLoginBtn: true,
      isUserMenuOpen: false,
      stateMenuParcel: 'SHOW',
      showPanelToMenu: false,
    };
  },
  getters: {
    transformedMenuById({ menu }) {
      function transformMenuStructure(items: IMenuItem[], parentPath = ''): Record<number, string> {
        return items.reduce((acc, item) => {
          const currentPath = parentPath ? `${parentPath}->${item.title}` : item.title;

          // Добавляем текущий элемент в объект
          acc[item.id] = currentPath;

          // Если есть дочерние элементы, добавляем их рекурсивно
          if (item.children.length > 0) {
            Object.assign(acc, transformMenuStructure(item.children, currentPath));
          }

          return acc;
        }, {} as Record<number, string>);
      }

      // Преобразование меню из состояния
      return transformMenuStructure(menu);
    },
    individualsSubmenu({ menu }) {
      return menu?.[0]?.children || [];
    },
    activePath({ menu, activeMenu }) {
      if (!activeMenu) {
        return [];
      }

      const findActivePath = (o: IMenuItem) => {
        if (o.id === activeMenu?.id) return [o];

        if (!o.children) return [];

        let curEl: IMenuItem[] | undefined;
        o.children.find((x) => (curEl = findActivePath(x)));

        if (curEl && curEl.length) {
          return [o, ...curEl];
        }

        return undefined;
      };

      return menu.map((el) => findActivePath(el)).find((el) => !!el) || [];
    },
  },
  actions: {
    setMenu(payload: IMenuItem[]) {
      this.menu = payload;
    },
    setMobileMenu(payload: boolean) {
      this.isMobileMenuOpen = payload;
    },
    setActiveMenu(payload: IMenuItem) {
      this.activeMenu = { ...payload };
    },
    updateStateMenu(payload: { state: string }) {
      this.stateMenuParcel = payload.state;
    },
    updateStateShowPanelToMenu(payload: { showPanelToMenu: boolean }) {
      this.showPanelToMenu = payload.showPanelToMenu;
    },
    setUserMenuOpen(payload: boolean) {
      this.isUserMenuOpen = payload;
    },
    updateActiveMenuByUrl(url: string) {
      const localeRegex = /^(\/\w\w\/|\/)/;
      const urlToFindWithoutLocale = url.replace(localeRegex, '');

      const findMenuItemByUrl = (o: IMenuItem): IMenuItem | undefined => {
        if (!o.url) {
          return;
        }

        const currentUrlWithoutLocale = o.url.replace(localeRegex, '');

        if (urlToFindWithoutLocale === currentUrlWithoutLocale) return o;

        if (!o.children) return;

        return o.children.find((x) => findMenuItemByUrl(x));
      };

      const menuItemsByUrl = this.menu.map((el: IMenuItem) => findMenuItemByUrl(el));

      const active = menuItemsByUrl?.find((el) => !!el);

      if (active) {
        this.setActiveMenu(active);
      } else {
        this.setActiveMenu(this.menu[0]);
      }
    },
    openMobileMenu() {
      this.setMobileMenu(true);
    },
    closeMobileMenu() {
      this.setMobileMenu(false);
    },
    updateMenuForAuthorized(locale: string) {
      const newMenu = this.menu.map((item) => changeSiteUrls(item, locale));
      this.setMenu(newMenu);
    },
  },
});

<template>
  <base-cdek-modal ref="modalRef" :name="modalName" @close="closeModal">
    <cdek-auth
      :notification-text="notificationText"
      :alert="alert"
      :allow-login="allowLogin"
      :is-redirect="isRedirect"
      :legal-only="legalOnly"
      :is-marketplace="isMarketplace"
      @success="onSuccess"
    />
  </base-cdek-modal>
</template>

<script lang="ts" setup>
import CdekAuth from '@/components/Auth/CdekAuth.vue';
import useModal from '@/composables/use-modal';
import useLoadUser from '@/components/Login/use-load-user';
import { modalName } from '@/components/Login/consts';
import BaseCdekModal from '@/components/BaseComponents/BaseCdekModal.vue';
import { reachGoal, sendParams } from '@/utils/reach-goal';
import { AUTH_AB } from '@/constants/metrics';
import { CATEGORY, useTestAbc } from '@/composables/use-test-abc';
import useSiteInfo from '@/composables/use-site-info';
import { useKeycloak } from '@/composables/use-keyclock';
import useSettings from '@/composables/use-settings';
import { useLocalStorage } from '@vueuse/core';
import { nextTick, ref } from 'vue';

interface RedirectMapItem {
  from: string;
  to: string;
  withParams?: boolean;
  isCalculate?: boolean;
  isModal?: boolean;
}

const redirectMap: RedirectMapItem[] = [
  {
    from: 'gifts',
    to: 'cabinet/gifts/',
  },
  {
    from: 'box',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'profi',
    to: 'cabinet/calculate/',
    isCalculate: true,
  },
  {
    from: 'tracking',
    to: 'cabinet/tracking/',
    withParams: true,
  },
  {
    from: 'payment',
    to: 'payment/',
    isModal: true,
  },
];

const { initLogin } = useKeycloak();

const modalRef = ref(null);

const { currentLocale, isRuSite } = useSiteInfo();

const { initTest, isSample } = useTestAbc(CATEGORY.SSO);

const preparePathname = (url: string) => {
  const endSlash = url.match(/\/$/) ? '' : '/';

  return `/${currentLocale.value}/${url}${endSlash}`;
};

const startsWith = (pathname: string) => {
  return window.location.pathname.startsWith(preparePathname(pathname));
};

const ssoRedirectUri = (locale = 'ru') => {
  const { origin } = window.location;

  const url = new URL(origin);
  url.pathname = `/resolver/`;

  const foundRedirect = redirectMap.find((item) => {
    return startsWith(item.from) ? item : false;
  });

  url.searchParams.set('locale', locale);

  if (foundRedirect) {
    const params = foundRedirect.withParams ? window.location.search : '';

    const redirect = preparePathname(foundRedirect.to) + params;

    url.searchParams.set('redirect', btoa(redirect));
  }

  return url.href;
};

interface LoginAlert {
  title: string;
  url: string;
  target: string;
}

interface IProps {
  allowLogin?: boolean;
  withLoadUser: boolean;
  isRedirect: boolean;
  notificationText: string;
  alert?: LoginAlert;
  legalOnly?: boolean;
  isMarketplace?: boolean;
}

const props = withDefaults(defineProps<IProps>(), { allowLogin: true });

const modal = useModal();
const userLoader = useLoadUser();

const { getSettingsGroup } = useSettings();

const { SSO_DISABLED } = getSettingsGroup('all');
const isOpenedSSO = useLocalStorage<boolean>('sso_open', false);

const closeModal = () => {
  modal.hide(modalName);
};

if (!SSO_DISABLED && !props.legalOnly) {
  initTest(10);

  let isNewAuth = false;
  if (isRuSite.value || isOpenedSSO.value) {
    isNewAuth = isSample('A', 'B', 'C', 'D', 'E');
  }

  sendParams({
    [AUTH_AB.VISIT_PARAM]: isNewAuth ? 'new' : 'old',
  });

  reachGoal(AUTH_AB.AUTH_ATTEMPT);

  if (isNewAuth) {
    const url = ssoRedirectUri(currentLocale.value);

    nextTick(() => {
      closeModal();

      initLogin(url);
    });
  }
}

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const onSuccess = async () => {
  if (props.withLoadUser) {
    await userLoader.load();
  }

  emit('success');
  closeModal();
};
</script>

<style lang="scss" scoped>
.login-modal {
  display: flex;
  align-items: center;

  &__content {
    position: relative;
    margin: auto;
    background: #fff;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    max-width: 400px;
  }

  &.v--modal {
    padding: 32px 40px;
    border-radius: 10px;
  }

  &__close {
    padding: 22px 18px;
    outline: none;
    line-height: 0;
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__container {
    max-width: 320px;
  }
}
</style>

<template>
  <vue-final-modal
    content-class="cabinet-modal require-email-modal"
    :name="name"
    :click-to-close="false"
    :classes="['cabinet-modal-container']"
  >
    <modal-close-btn :name="name" />
    <div class="title">
      {{ t('fillEmailModal.title') }}
    </div>
    <div class="description">
      {{ t('fillEmailModal.description') }}
    </div>

    <cdek-form class="form" @submit="setEmail">
      <div class="form-control">
        <cdek-form-control
          v-test-id="'email-confirm-input'"
          label="Email"
          name="email"
          :rules="!activatedRules ? 'required' : 'required|email'"
          @blur="activateRules"
        />
      </div>
      <cdek-checkbox v-if="isRuSite" v-model="agree" class="checkbox">
        <i18n-t keypath="advertAgreement.checkbox.text">
          <template #link>
            <a href="https://www.cdek.ru/ru/soglasienareklamu/" target="_blank">
              {{ t('advertAgreement.checkbox.link') }}
            </a>
          </template>
        </i18n-t>
      </cdek-checkbox>
      <cdek-button
        v-test-id="'email-confirm-btn'"
        class="step-actions__btn"
        type="submit"
        :loading="isLoading"
      >
        {{ t('confirm') }}
      </cdek-button>
    </cdek-form>
  </vue-final-modal>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { reachGoal } from '@/utils/reach-goal';
import { CdekButton, CdekForm, CdekFormControl, useToast, formSettings } from '@cdek-ui-kit/vue';
import { computed, ref } from 'vue';
import useModal from '@/composables/use-modal';
import { useI18n } from 'vue-i18n';
import CdekCheckbox from '@/components/BaseComponents/CdekCheckbox.vue';
import useAdvertAgreement from '@/components/Login/use-advert-agreement';
import useConfirmEmail from '@/components/Login/use-confirm-email';
import type { ShowError } from '@/types/error';
import { ADVERT_AGREEMENT } from '@/constants/metrics';
import useCabinetApi from '@/composables/use-cabinet-api';
import useSiteInfo from '@/composables/use-site-info';
import { captureClientException } from '@/utils/handleErrors/handleErrors';
import ModalCloseBtn from './CloseBtn.vue';

const name = 'require-email-modal';

const activatedRules = ref(false);
const isLoading = ref(false);
const { apiClient } = useCabinetApi();
const store = useStore();
const confirm = useConfirmEmail();
const modal = useModal();
const toast = useToast();
const advertAgreement = useAdvertAgreement();
const { t } = useI18n();
const { isRuSite } = useSiteInfo();

const locale = computed(() => store.getters.currentLocale);

const uiKitAddMessages = (rule: string, message: string) => {
  formSettings.addMessages(rule, {
    [locale.value]: message,
  });
};

const isMobile = window?.matchMedia('(max-width: 768px)');
const toastSettings = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  position: isMobile.matches ? 'top-center' : 'top-right',
};
const agree = ref(false);

const activateRules = () => {
  activatedRules.value = true;
};

const setEmail = async (values: { email: string }) => {
  isLoading.value = true;

  try {
    await apiClient.setEmail(values.email);
    store.commit('cabinet/setUserEmail', values.email);

    if (isRuSite.value) {
      advertAgreement.save(agree.value);

      if (agree.value) reachGoal(ADVERT_AGREEMENT.LK_MAIL_AD_CONFIRM);
    }

    modal.hide(name);
    confirm.showConfirmModal();
  } catch (e) {
    const errors = (e as ShowError).response?.data?.errors || [];

    toast.error(
      {
        title: t('error'),
        text: errors[0] || '',
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toastSettings,
    );

    captureClientException({ error: e, place: 'FillEmailModal' });
  } finally {
    isLoading.value = false;
  }
};

if (isRuSite.value) reachGoal(ADVERT_AGREEMENT.LK_MAIL);

uiKitAddMessages('email', t('validEmail'));
uiKitAddMessages('required', t('requiredField'));
</script>

<style lang="scss">
.require-email-modal {
  @include media-min-md {
    max-width: 432px;
  }
}
</style>

<style scoped lang="scss">
.title {
  @include headline-5;

  margin-bottom: 8px;

  @include media-min-md {
    @include headline-4;

    margin-bottom: 4px;
  }
}

.description {
  @include body-1;
}

.form {
  margin-top: 16px;
}

.form-control {
  // На сам cdek-form-control класс вешается без scoped (баго-фича) поэтому враппер
  margin-bottom: 16px;

  :deep(.cdek-input__tip:empty) {
    display: none;
  }
}

.checkbox {
  margin-bottom: 40px;
}
</style>
